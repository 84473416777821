import Script from 'next/script'

function TrustpilotCarousel({
  // All defaults are for the Trustpilot carousel on the homepage v2 design.
  businessunitId = '63926a7233fd74b504596428',
  className = 'trustpilot-widget',
  fontFamily = 'Ultramarine',
  locale = 'en-US',
  reviewLanguage = 'en',
  reviewsUrl = 'https://www.trustpilot.com/review/achieve.com',
  scriptSrc = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
  stars = '4,5',
  styleHeight = '240px',
  styleWidth = '100%',
  tags = '',
  templateId = '54ad5defc6454f065c28af8b',
  theme = 'light',
  reference,
}) {
  return (
    <>
      <Script src={scriptSrc} defer />

      <div
        ref={reference}
        data-businessunit-id={businessunitId}
        className={className}
        data-font-family={fontFamily}
        data-locale={locale}
        data-review-languages={reviewLanguage}
        data-stars={stars}
        data-style-height={styleHeight}
        data-style-width={styleWidth}
        data-tags={tags}
        data-template-id={templateId}
        data-theme={theme}
      >
        <a href={reviewsUrl} target="_blank" rel="noreferrer">
          Trustpilot
        </a>
      </div>
    </>
  )
}

export { TrustpilotCarousel }
